import React, { useState, useEffect  } from 'react'
import { Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { Form, Container, Row, Col, Grid, Spinner, Modal, Button, ListGroup, ListGroupItem, Glyphicon } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Search, Upload } from 'react-bootstrap-icons';
import Amplify, { API, Storage } from 'aws-amplify';
// import { v4 as uuid } from 'uuid';

function ReferralSuccess(props){
    return (
        <input type="file"   />
    )
}
export default ReferralSuccess;