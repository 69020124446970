import React, { useContext, useEffect, useState } from 'react'
import logo from '../bwlogo.png';
import DataContext from "./DataContext";
import axios from 'axios';
import AlertDismissable from './Alert'; 
import publicIp from "public-ip";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';


function Index(props) {

    const {match: {params}} = props;
    
    console.log("PARAMS:");
    console.log(params);

    console.log("PIN:");
    console.log(params.pin);

    const { settings, setSettings } = useContext(DataContext);
    const history = useHistory();
    const location = useLocation();
    const [sourceIp, setSourceIp] = useState(''); 

    // Handle Pin code logic - if in the url pull from there first, check check localStorage
    let pincode = "";
    if (params.pin) {
        pincode = params.pin;
    } else {
        pincode = localStorage.getItem('bwShortCode') ? JSON.parse(localStorage.getItem('bwShortCode')).id : "";
    }
    const [shortcode, setShortcode] = useState(pincode);
    console.log("shortcode: ", shortcode);

    
    const storeSettings = (data) => {
        console.log('STORAGE DATA: ', data);
        const hub_data = {
            tenant: data.tenant.S,
            facility_id: data.facility_id.S,
            facility_name: data.hub_facility_name.S,
            fax_number: "(575) 556-5818",
            phone_number: "(575) 556-6338"
        }
        const gw_data  = {
            short_code: data.short_code.S,
            key_token: data.general_settings.M.key_token.S,
            id: data.general_settings.M.id.S,
            tenant: data.tenant.S,
            facility_id: data.facility_id.S,
            facility_name: data.hub_facility_name.S,
            location_name: data.location_name.S,
            location_type: data.location_type.S,
            transfer_location_id: data.transfer_location_id.S,
            main_phone: data.general_settings.M.main_phone.S,
            banner: data.general_settings.M.banner.S,
            type: data.general_settings.M.cg_type.S,
            direct_admit: data.general_settings.M.direct_admit.BOOL,
            outpatient_da: data.general_settings.M.outpatient_da.BOOL,
            external_facility: data.general_settings.M.external_facility.BOOL,
            community_gateway: data.general_settings.M.community_gateway.BOOL,
            inpatient_transfer: data.general_settings.M.inpatient_transfer.BOOL
            // outpatient_referral: data.general_settings.M.outpatient_referral.BOOL
        }

        window.localStorage.setItem('hub_data', JSON.stringify(hub_data));
        window.localStorage.setItem('gw_data',  JSON.stringify(gw_data));

        const data_context = {
            hub_data: hub_data,
            gw_data: gw_data,
            referral: settings.referral
        }
        setSettings(data_context);

        return;
    }
    
    
    async function getIpAddress() {
        // console.log(await publicIp.v4());
        //=> '46.5.21.123'
    
        // console.log(await publicIp.v6());
        //=> 'fe80::200:f8ff:fe21:67cf'
        setSourceIp(await publicIp.v4({fallbackUrls: [
            'https://ifconfig.co/ip'
        ]}));
        return sourceIp
    }
    useEffect(() => {
    getIpAddress();
    },[])
    
    async function handleGetFacility(e) {
        e.preventDefault();
        
        localStorage.setItem('bwShortCode', JSON.stringify({id: shortcode}));    
        let target = `https://t8p53amzd6.execute-api.us-east-1.amazonaws.com/dev/communityGatewayShortcode?shortcode=${shortcode}&sourceIP=${sourceIp}`
        
        let response = await axios.get(target)
        .then(response => response.data)
        .catch(err => console.log("err: ", err));
        
        console.log("response: ", response);

        if ( response ) {
            storeSettings(response);
            await new Promise(resolve => setTimeout(resolve, 1000));
            history.push("/tracker/", { params: {shortcode: shortcode, facility: response} });
        } else {
            history.push("/index", { params: {failed: true} });
        }
    }
  

    let foShow = false
    let variant = ""
    let heading = ""
    let message = ""

    if (location.state && location.state.params.failed === true) {
        getIpAddress()
        foShow = true
        variant = "danger"
        heading = "Shortcode Failed"
        message = `No facility was found with that shortcode and or IP address(${sourceIp}), please try again or verify that you have the correct code.`
    }

    function handleAlertDismiss(e) {
        e.preventDefault()
        foShow = false
        variant = ""
        heading = ""
        message = ""
    }
    return (
        <Container>
            <AlertDismissable foShow={foShow} variant={variant} heading={heading} message={message} onclick={e =>handleAlertDismiss(e)}/>

            <Row className="justify-content-md-center">
                <Col md="6">
                    <img
                        src={logo}
                        className="d-inline-block align-top"
                        alt="BedWatch"
                    />
                </Col>
            </Row>
            <Row className="justify-content-md-center" style={{marginTop:"15px"}}>
                <Col md="6">
                <Form className="add-post" onSubmit={e => handleGetFacility(e)} >
                    <Card className="text-center">
                        <Card.Header className=" text-white bg-primary"><h4>Community Gateway</h4></Card.Header>
                        <Card.Body>
                            <Card.Title></Card.Title>
                            <Card.Text>
                            Please enter your pin.
                            </Card.Text>
                            <Col>
                                <Form.Group className="mb-3" controlId="formPin">
                                    <Form.Control type="text" 
                                                placeholder="####" 
                                                className="text-center mx-auto"
                                                style={{width:"30%"}}
                                                name="facilityShortCode"
                                                required
                                                value={shortcode}
                                                onChange={e => setShortcode(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Col>
                        </Card.Body>
                        
                    </Card>
                </Form>
                </Col>
            </Row>

        </Container>
    ) 
}

export default Index;


