import React, { useState, useEffect } from 'react'
import { Badge, Button } from 'react-bootstrap';

function Counter(props) {

  const [counter, setCounter] = useState(60);
  const pause = props.pause;
  console.log("pause:", pause);

  function reloadNow() {
    window.location.reload(true);
  }

  useEffect(() => {
    if(pause != true) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    } 
    if (counter == 0) {
      reloadNow();
    }
  }, [counter, pause]);

  return (
    <div className="text-end d-none d-sm-block">
      <Badge bg="secondary">{counter}</Badge> 
      <Button type="button" 
              variant="outline-secondary" 
              style={{marginLeft: "10px"}}
              onClick={reloadNow}
      >
        Reload 
      </Button>
    </div>
  );
}

export default Counter;