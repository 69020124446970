import React, { useState  } from 'react'
import PdfButton from './PdfButton';
import PdfEmail from './PdfEmail';
import 'react-phone-number-input/style.css'
import { format } from 'date-fns'
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import { Search, Circle, CircleFill, CheckCircleFill, DashCircleFill } from 'react-bootstrap-icons';




function VerticalTracker(props) {
  console.log("Vertical Tracker", props);
    
  const [show, setShow] = useState(false);
  
  const tsCapacityApproved = (props.request.ts_capacity_approved ? `${format(new Date(props.request.ts_capacity_approved), "MM/dd/yyyy")} @ ${format(new Date(props.request.ts_capacity_approved), "h:mm a")}` : "")
  
  const capacityHeader = (props.request.ts_capacity_approved ? <h4 style={{color: "black"}}>Assigned</h4> : <h4 style={{color: "gray"}}>Assigned</h4>)
  
  const statusHeader = (props.request.ts_accepted ? <h4 style={{color: "black"}}>Status: {props.request.transfer_center_status} </h4> : <h4 style={{color: "gray"}}>Status: {props.request.transfer_center_status} </h4>)

  let statusTime = "" 
  
  if (props.request.transfer_center_status === 'accepted' && props.request.ts_accepted) {
    statusTime = <span>Date/Time: {`${format(new Date(props.request.ts_accepted), "MM/dd/yyyy")} @ ${format(new Date(props.request.ts_accepted), "h:mm a")}`} </span>  
  } else if (props.request.transfer_center_status === 'declined' &&  props.request.ts_denied) {
    statusTime = <span>Date/Time: `${format(new Date(props.request.ts_denied), "MM/dd/yyyy")} @ ${format(new Date(props.request.ts_denied), "h:mm a")}` </span>
  }
  
  const bedAssignedDate =  props.request.ts_bed_assigned ? `${format(new Date(props.request.ts_bed_assigned), "MM/dd/yyyy")} @ ${format(new Date(props.request.ts_bed_assigned), "h:mm a")}` : ""
  
  const clickEmail = () => {
    setShow(false)
    props.pauseCounterFunc(false)
  }
 
  const openPdfTracker = (data) => {
    setShow(data)
    props.pauseCounterFunc(data)
  }

  const greenCircle = <CheckCircleFill size ={35} style={{color: "green", backgroundColor: "white", marginTop:"25px"}}/>
  const grayCircle  = <Circle size ={35} style={{color: "gray", backgroundColor: "white", marginTop:"25px"}}/>
  const redCircle   = <DashCircleFill size ={35} style={{color: "red", backgroundColor: "red", marginTop:"25px"}}/>

  let requestedCircle   = greenCircle;
  let assignedCircle    = (props.request.ts_capacity_approved ? greenCircle : grayCircle);
  let statusCircle      = grayCircle;
  let bedAssignedCircle = (props.request.ts_bed_assigned ? greenCircle : grayCircle);
  let patArrivedCircle  = (props.request.ts_patient_delivered ? greenCircle : grayCircle);

  if (props.request.transfer_center_status === 'accepted') {
    statusCircle = greenCircle;
  } else if (props.request.transfer_center_status === 'denied'){
    statusCircle = redCircle;
  }


  return (
    <>
      <Button  variant={"btn btn-outline-primary btn-sm"} onClick={e => openPdfTracker(true)}>View Status</Button>
      <Modal show={show} aria-labelledby="centered" size="lg">
        <Modal.Header closeButton onClick={e => openPdfTracker(false)} >
          <Modal.Title>Tracking Details - Request #{props.request.transfer_center_jobnumber}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Row>
            <Col md={6}>
              <PdfButton request={props.request} facility={props.facility} text={"Download PDF"}/>
            </Col>
            <Col md={6}>
              <PdfEmail request={props.request} clickEmail={openPdfTracker}  />
            </Col>
          </Row>

        <hr/>
        <Table responsive>
          <tbody>
            <tr>
              <td style={{width:"20%"}} className="text-center">
                {requestedCircle}
              </td>
              <td style={{width:"25%"}}>
                <h5 style={{fontSize: "1.1em"}}>Requested Transfer</h5>
                <div style={{ marginTop:"-5px"}}>
                <span style={{fontSize: ".8em"}}> Date/Time:</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}> Referring Contact:</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}> Referring Physician:</span>
                </div>
              </td>
              <td style={{width:"55%"}}>
                <h5 style={{fontSize: "1.1em", color: "white"}}>Requested Transfer</h5>
                <div style={{ marginTop:"-5px"}}>
                <span style={{fontSize: ".8em"}}>{format(new Date(props.request.ts_bed_requested), "MM/dd/yyyy")} {format(new Date(props.request.ts_bed_requested), "h:mm a")} 
                </span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}>{props.request.contact_name}  @ {props.request.contact_number} </span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}>{props.request.physician_name}  @ {props.request.physician_number} </span>
                </div>
              </td>
            </tr>

            <tr>
              <td style={{width:"20%"}} className="text-center">
                {assignedCircle}
              </td>
              <td style={{width:"25%"}}>
                <h5 style={{fontSize: "1.1em"}}>Assigned</h5>
                <div style={{ marginTop:"-5px"}}>
                <span style={{fontSize: ".8em"}}> Date/Time:</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}> Accepting Nurse:</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}> Nurse Phone:</span>
                </div>
              </td>
              <td style={{width:"55%"}}>
                <h5 style={{fontSize: "1.1em", color: "white"}}>Assigned</h5>
                <div style={{ marginTop:"-5px"}}>
                  <span style={{fontSize: ".8em"}}>{props.request.ts_capacity_approved ? `${format(new Date(props.request.ts_capacity_approved), "MM/dd/yyyy")} @ ${format(new Date(props.request.ts_capacity_approved), "h:mm a")}` : ""} 
                  </span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                  <span  style={{fontSize: ".8em"}}>{props.request.receiving_nurse_name}</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                  <span  style={{fontSize: ".8em"}}>{props.request.receiving_nurse_phone}</span>
                </div>
              </td>
            </tr>

            <tr>
              <td style={{width:"20%"}} className="text-center">
                {statusCircle}
              </td>
              <td style={{width:"25%"}}>
                <h5 style={{fontSize: "1.1em"}}>Status: {props.request.transfer_center_status}</h5>
                <div style={{ marginTop:"-5px"}}>
                <span style={{fontSize: ".8em"}}> Date/Time:</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}> Accepting Physician:</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}> Physician Number:</span>
                </div>
              </td>
              <td style={{width:"55%"}}>
                <h5 style={{fontSize: "1.1em", color: "white"}}>Status: {props.request.transfer_center_status}</h5>
                <div style={{ marginTop:"-5px"}}>
                  <span style={{fontSize: ".8em"}}>{statusTime}</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                  <span  style={{fontSize: ".8em"}}>{props.request.accepting_physician_name} </span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                  <span  style={{fontSize: ".8em"}}>{props.request.accepting_physician_phone} </span>
                </div>
              </td>
            </tr>

            <tr>
              <td style={{width:"20%"}} className="text-center">
                {bedAssignedCircle}
              </td>
              <td style={{width:"25%"}}>
                <h5 style={{fontSize: "1.1em"}}>Assigned Bed</h5>
                <div style={{ marginTop:"-5px"}}>
                <span style={{fontSize: ".8em"}}> Date/Time:</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}> Unit:</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}> Bed:</span>
                </div>
              </td>
              <td style={{width:"55%"}}>
                <h5 style={{fontSize: "1.1em", color: "white"}}>Assigned Bed</h5>
                <div style={{ marginTop:"-5px"}}>
                  <span style={{fontSize: ".8em"}}>{bedAssignedDate} 
                  </span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                  <span  style={{fontSize: ".8em"}}>{props.request.unit_name}</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                  <span  style={{fontSize: ".8em"}}>{props.request.bed_name}</span>
                </div>
              </td>
            </tr>

            <tr>
              <td style={{width:"20%"}} className="text-center">
                {patArrivedCircle}
              </td>
              <td style={{width:"25%"}}>
                <h5 style={{fontSize: "1.1em"}}>Patient Arrived</h5>
                <div style={{ marginTop:"-5px"}}>
                <span style={{fontSize: ".8em"}}> Date/Time:</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}> Admitting Physician:</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                <span  style={{fontSize: ".8em"}}> Attending Physician:</span>
                </div>
              </td>
              <td style={{width:"55%"}}>
                <h5 style={{fontSize: "1.1em", color: "white"}}>Patient Arrived</h5>
                <div style={{ marginTop:"-5px"}}>
                  <span style={{fontSize: ".8em"}}>{props.request.ts_patient_delivered ? `${format(new Date(props.request.ts_bed_requested), "MM/dd/yyyy")} @ ${format(new Date(props.request.ts_bed_requested), "h:mm a")}` : ""} 
                  </span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                  <span  style={{fontSize: ".8em"}}>{props.request.phys_admit}</span>
                </div>

                <div style={{ marginTop:"-5px"}}>
                  <span  style={{fontSize: ".8em"}}>{props.request.phys_attend}</span>
                </div>
              </td>
            </tr>
           

           
          </tbody>
        </Table>


        </Modal.Body>
        <Modal.Footer>
          <Button onClick={e => openPdfTracker(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default VerticalTracker;