import React, { useEffect, useState } from 'react'
import logo from '../bwlogo.png';
import { useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas'
import publicIp from "public-ip";
import { Container, Modal, Row, Badge, Dropdown, Nav, Navbar, NavDropdown, Form, FormControl } from 'react-bootstrap';

function MainNavbar(props) {
    let location = useLocation().pathname;
    console.log("location: ", location)
    const [sourceIp, setSourceIp] = useState(''); 
    const [showIpAddress, setShowIpAddress] = useState(false); 
    (async () => {
        console.log(await publicIp.v4());
        setSourceIp(await publicIp.v4());
    })();
    const openIpAddress = (data) => {
        setShowIpAddress(data);
    };

    function logout() {
        window.localStorage.removeItem('hub_data');
        window.localStorage.removeItem('gw_data');
        window.localStorage.removeItem('referral');
    }

    const first_paths = ['','cg','index'];
    let show_nav = (first_paths.includes(location.split('/')[1])) ? false : true;
    
    const navbar = ((show_nav) ? (<Navbar bg="light" expand={'md'}>
    <Container fluid>
        <Navbar.Brand href="/">
            <img
                src={logo}
                className="d-inline-block align-top"
                alt="BedWatch"
            />
        </Navbar.Brand>
        <Navbar.Collapse>
            <Nav.Link href="/tracker/">Inpatient Referrals</Nav.Link>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
            <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic" size="lg">
                    Community Gateway
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="/tracker/">Inpatient Transfers</Dropdown.Item>
                    <Dropdown.Item href="/tracker/">New Transfer</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item  onClick={e => logout()} href="/">Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Navbar.Collapse>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
        placement="end"
        >
        <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">Community Gateway</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/tracker/">Inpatient Transfers</Nav.Link>
                <Nav.Link href="/tracker/">New Transfer</Nav.Link>
                <NavDropdown.Divider />
                <Nav.Link href="#action2">Logout</Nav.Link>
            </Nav>
        </Offcanvas.Body>
        </Navbar.Offcanvas>
    </Container>
    <Modal show={showIpAddress} aria-labelledby="centered" size="lg">
        <Modal.Header closeButton onClick={e => openIpAddress(false)} >
            <Modal.Title style={{marginLeft: "35px"}}>IP Address</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{marginLeft: "20px"}}>
            <Row style={{marginLeft: "20px"}}>
                {sourceIp}
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={e => openIpAddress(false)}>Close</Button>
        </Modal.Footer>
    </Modal>
    </Navbar>
    ) : (<div></div>)
   )




    
   

    
    return (
        <div>
            {navbar}
        </div>
    );
  }
  

  export default MainNavbar;