import React, { useState, useEffect  } from 'react'
import axios from 'axios';
import { Button, Form, Container, Row, Col, Grid, Spinner, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function PdfEmail(props){
    const [email, setEmail] = useState("");
    console.log("Emailprops: ", props)
    console.log('props.request.tenant', props.request.tenant);
    async function emailPDF() {
        props.clickEmail(false)
        // console.log("Email: ", email)
        const target = `https://t8p53amzd6.execute-api.us-east-1.amazonaws.com/dev/communityGatewayVTrackerPDF?email=${email}`
        let result = await axios(target, {method: 'POST', // *GET, POST, PUT, DELETE, etc.
        data: JSON.stringify({email: email, request_id: props.request.id, facility_id: props.request.facility_id, tenant: props.request.to_tenant})
        }).then((response) => {
            // console.log("Email PDF: ", response.data)
        }).catch(err => console.log(err))
    }

    return (
        <Form>
            <Row className="align-items-center">
                <Col sm={8} className="my-1">
                <Form.Label htmlFor="inlineFormInputEmail" visuallyHidden>
                    Email
                </Form.Label>
                <Form.Control placeholder="Email Address" 
                    size="sm" 
                    name="email" 
                    required value={email} 
                    onChange={e => setEmail(e.target.value)}
                    />
                </Col>
                <Col xs="auto" className="my-1">
                <Button size="sm" variant="outline-secondary" onClick={e => emailPDF()}>Email PDF</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default PdfEmail;
