import React, { useState, useEffect, useContext } from 'react'
import { Storage } from 'aws-amplify';
import { format } from 'date-fns'
import DataContext from "./DataContext";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../App.css"
import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';
import { ThermometerSun } from 'react-bootstrap-icons';
import { v4 as uuid } from 'uuid';


function CreateReferral() {
  const { settings, setSettings } = useContext(DataContext);
  const [fileData, setFileData] = useState(null);
  const [description, setDescription] = useState("");
  const gw  = settings.gw_data;
  const hub = settings.hub_data;

  console.log('GW: ', gw);
  console.log('HUB: ', hub);
  
  const [serviceLineOptions, setServiceLineOptions] = useState([]); 
  const slSelectOptionsList = serviceLineOptions.map((selectOption, index) => {
    return <option key={index} value={selectOption}>{selectOption}</option>
  });
  let history = useHistory();

  async function handleNewReferral() {
    let body = JSON.stringify({facility_id: hub.facility_id, tenant: hub.tenant})
    console.log("body: ", body)
    const target = 'https://t8p53amzd6.execute-api.us-east-1.amazonaws.com/dev/cvg2NewReferral'
    let result = await axios(target, {method: 'POST', // *GET, POST, PUT, DELETE, etc.
    data: JSON.stringify({facility_id: hub.facility_id, tenant: hub.tenant})
    }).then((response) => {
        setServiceLineOptions(response.data)
    }).catch(err => console.log(err))
  };

  const [ form, setForm ] = useState({
    first_name: "",
    last_name: "",
    patient_gender: "",
    patient_dob_str: "",
    diagnosis: "",
    ac_service_line: "",
    emergency_transfer: "false",
    covid_19: "",
    contact_name: "",
    contact_phone: "",
    contact_email: "",
    physician_name: "",
    physician_number: ""
  });
  const [ validated, setValidated ] = useState(false);

  const handleInputChange = (event) => {
    setForm({...form, [event.target.name]: event.target.value }) 
  }

  function updateContactPhoneNumber(number) {
    setForm({...form, contact_number: number})
  }
  function updatePhysicianPhoneNumber(number) {
    setForm({...form, physician_number: number})
  }

  useEffect(() => {
    handleNewReferral()
  }, []);    
  


  async function handleCreateReferral(data) {
    const ID = uuid();
    data.dynamo_id = ID;
    let fileUpload = "None";
    let fileUploaded = false;
    if (fileData && description) {
      const fileName = fileData.name.split(/[. ]+/).pop();
      Storage.put(`${gw.facility_id}/cgv2_document_with_request/${ID}.${fileName}`, fileData, 
      {
        metadata: {description: description, document_type: "upload", user: `${gw.transfer_location_id}`, transfer_location_name: `${gw.location_name}`, upload_date: format(new Date(Date.now()), "MM/dd/yyyy h:mm a")}
      })
      .then((response) => {
        fileUpload = `${description} Uploaded Succesffuly`
        fileUploaded = true
      }).catch((err) => {
        fileUpload = `${description} Failed to Upload`
      }).finally(() => {
        // stage prod url
        const target = "https://ivhzj064ah.execute-api.us-east-1.amazonaws.com/prod/transfer_request"

        // stage test url
        // const target = "https://ddsz1dax5g.execute-api.us-east-1.amazonaws.com/test/transfer_request"

        console.log("referral: ", data)    
        axios(target, {
          method: 'POST',
          data: JSON.stringify({referral: data, facility_id: gw.facility_id, tenant: gw.tenant, file_uploaded: fileUploaded, file_name: `${ID}.${fileName}`})
        }).then((response) => {
          console.log("result.data", response)
          let alertNotice = {}          
          data.transfer_center_status = "in transit"
          data.status_tracker_class = "btn-secondary"
          data.ts_bed_requested = new Date().getTime()
          window.localStorage.setItem('referral', JSON.stringify(data));
          setSettings({
            ...settings, 
            ["referral"]: data}
          );
          // console.log("settings: ", settings);
          alertNotice = {status: "success", variant: "success", heading: "Request Created", message: `you have created a new transfer request ${fileUpload}`}
          history.push("/tracker/", { params: {shortcode: gw.short_code, facility: settings, alertNotice: alertNotice}});
        }).catch((err) => {
          let alertNotice = {}
          alertNotice = {status: "failure", variant: "danger", heading: "Request Failed", message: `the transfer request was not successful` }
          history.push("/tracker/", { params: {shortcode: gw.short_code, facility: settings, alertNotice: alertNotice}});
        });
      });
    } else {
      // stage prod url
      const target = "https://ivhzj064ah.execute-api.us-east-1.amazonaws.com/prod/transfer_request"
      
      // stage test url
      // const target = "https://ddsz1dax5g.execute-api.us-east-1.amazonaws.com/test/transfer_request"
      console.log("referral: ", data)    
      axios(target, {
        method: 'POST',
        data: JSON.stringify({referral: data, facility_id: gw.facility_id, tenant: gw.tenant, file_uploaded: fileUploaded})
      }).then((response) => {
        console.log("result.data", response)
        let alertNotice = {}          
        data.transfer_center_status = "in transit"
        data.status_tracker_class = "btn-secondary"
        data.ts_bed_requested = new Date().getTime()
        window.localStorage.setItem('referral', JSON.stringify(data));
        setSettings({
          ...settings, 
          ["referral"]: data}
        );
        // console.log("settings: ", settings);
        alertNotice = {status: "success", variant: "success", heading: "Request Created", message: `you have created a new transfer request ${fileUpload}`}
        history.push("/tracker/", { params: {shortcode: gw.short_code, facility: settings, alertNotice: alertNotice}});
      }).catch((err) => {
        let alertNotice = {}
        alertNotice = {status: "failure", variant: "danger", heading: "Request Failed", message: `the transfer request was not successful` }
        history.push("/tracker/", { params: {shortcode: gw.short_code, facility: settings, alertNotice: alertNotice}});
      });
    }
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const referral = event.currentTarget;

    if (referral.checkValidity() === false) {
      event.stopPropagation();
    } else {
      console.log("FORM DATA:");
      console.log(form);

      const transfer = {
        from_transfer_location_id: gw.transfer_location_id,
        transfer_center_status: "pending",
        first_name: form.first_name,
        last_name: form.last_name,
        patient_dob_str: form.patient_dob_str,
        ac_service_line: form.ac_service_line,
        patient_gender: form.patient_gender,
        diagnosis: form.diagnosis,
        physician_name: form.physician_name,
        physician_number: form.physician_number,
        contact_name: form.contact_name,
        contact_number: form.contact_number,
        contact_email: form.contact_email,
        emergency_transfer: form.emergency_transfer,
        covid_19: form.covid_19
      }
      
      handleCreateReferral(transfer);
    }
    setValidated(true);
  };

  return (
    <Container fluid>
    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
      <Row>
        
        <Col sm={6} md={6}>
          <Card className="card text-dark bg-light mb-3">
            <Card.Header as="h5">Patient Info</Card.Header>
            <Card.Body>
                
              {/* FIRST NAME */}
              <Form.Group as={Row} className="mb-3" controlId="formHorizontalFirstName">
                <Form.Label column sm={4}>
                  Patient First Name
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    type="text" 
                    placeholder=""
                    required
                    className="required"
                    name="first_name"
                    value={form.first_name}
                    onChange={ e => handleInputChange(e) }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the patient's first name.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* LAST NAME */}
              <Form.Group as={Row} className="mb-3" controlId="formHorizontalLastName">
                <Form.Label column sm={4}>
                  Patient Last Name
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    type="text" 
                    placeholder=""
                    required
                    className="required"
                    name="last_name"
                    value={form.last_name}
                    onChange={ e => handleInputChange(e) }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the patient's last name.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* GENDER */}
              <Form.Group as={Row} className="mb-3" controlId="formHorizontalGender">
                <Form.Label column sm={4}>
                  Gender
                </Form.Label>
                <Col sm={3}>
                  <Form.Select 
                    required 
                    className="required"
                    name="patient_gender"
                    value={form.patient_gender}
                    onChange={ e => handleInputChange(e) }
                  >
                    <option></option>
                    <option>M</option>
                    <option>F</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Gender is required.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* DATE OF BIRTH */}
              <Form.Group as={Row} className="mb-3" controlId="formHorizontalDateOfBirth">
                <Form.Label column sm={4}>
                  Date of Birth
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    type="text" 
                    placeholder=""
                    required
                    className="required"
                    name="patient_dob_str"
                    value={form.patient_dob_str}
                    onChange={ e => handleInputChange(e) }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the patient's date of birth.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* DIAGNOSIS */}
              <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm={4}>
                  Diagnosis
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    as="textarea" 
                    rows={3} 
                    required 
                    className="required"
                    name="diagnosis"
                    value={form.diagnosis}
                    onChange={ e => handleInputChange(e) }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the patient's diagnosis.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* SERVICE LINE */}
              <Form.Group as={Row} className="mb-3" controlId="formHorizontalEDandCovid">
                <Form.Label column sm={4}>
                  Service Line
                </Form.Label>
                <Col sm={8}>
                  <Form.Select
                    name="ac_service_line"
                    value={form.ac_service_line}
                    onChange={ e => handleInputChange(e) }
                  >
                    <option value=''>Select Service Line</option>
                    {slSelectOptionsList}
                  </Form.Select>
                </Col>
              </Form.Group>

              {/* ED and COVID */}
              <Form.Group as={Row} className="mb-3" controlId="formHorizontalEDandCovid">
                  <Form.Label column sm={4}>
                    ED Transfer
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Select
                      name="emergency_transfer"
                      value={form.emergency_transfer}
                      onChange={ e => handleInputChange(e) }
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </Form.Select>
                  </Col>

               <Form.Label column sm={2}>
                  COVID-19
                </Form.Label>
                <Col sm={3}>
                  <Form.Select 
                    required 
                    className="required"
                    name="covid_19"
                    value={form.covid_19}
                    onChange={ e => handleInputChange(e) }
                  >
                    <option value=""></option>
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    COVID-19 status is required.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formHorizontalEDandCovid">
                <Form.Label column sm={4}>
                  Attach File(optional)
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    type="file" 
                    onChange={e => setFileData(e.target.files[0])} 
                  />
                  <br/>
                  <Form.Control style={{ font: '19px'}}
                    type="text" placeholder="patient charts"
                    name="description"
                    value={description}
                    // onChange={e => setDescription(e.target.value)}
                    onChange={e => setDescription(e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6} md={6}>
          <Card className="card text-dark bg-light mb-3">
            <Card.Header as="h5">Requester</Card.Header>
            <Card.Body>
              
                
                {/* CONTACT NAME */}
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalContactName">
                  <Form.Label column sm={4}>
                    Contact Name
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control 
                      type="text" 
                      placeholder=""
                      required
                      className="required"
                      name="contact_name"
                      value={form.contact_name}
                      onChange={ e => handleInputChange(e) }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide the contact's name.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {/* CONTACT PHONE */}
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalContactPhone">
                  <Form.Label column sm={4}>
                    Contact Phone #
                  </Form.Label>
                  <Col sm={8}>
                    <PhoneInput
                      inputProps={{
                        name: 'contact_phone',
                        required: true
                      }}
                      required
                      className="required"
                      placeholder={""}
                      country={'us'}
                      onlyCountries={['us']}
                      disableDropdown={true}
                      disableCountryCode={true}
                      value={form.contact_number}
                      onChange={updateContactPhoneNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide the contact's phone number.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {/* CONTACT EMAIL */}
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalContactEmail">
                  <Form.Label column sm={4}>
                    Contact Email
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control 
                      type="text" 
                      placeholder=""
                      name="contact_email"
                      value={form.contact_email}
                      onChange={ e => handleInputChange(e) }
                    />
                  </Col>
                </Form.Group>

                {/* REFERRING PHYSICIAN */}
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalReferringPhysician">
                  <Form.Label column sm={4}>
                    Referring Physician
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control 
                      type="text" 
                      placeholder=""
                      required
                      className="required"
                      name="physician_name"
                      value={form.physician_name}
                      onChange={ e => handleInputChange(e) }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide the referring physician's name.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {/* PHYSICIAN PHONE */}
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPhysicianPhone">
                  <Form.Label column sm={4}>
                    Physician's Phone #
                  </Form.Label>
                  <Col sm={8}>
                    <PhoneInput
                      inputProps={{
                        name: 'physician_number',
                        required: true
                      }}
                      required
                      className="required"
                      placeholder={""}
                      country={'us'}
                      onlyCountries={['us']}
                      disableDropdown={true}
                      disableCountryCode={true}
                      value={form.physician_number}
                      onChange={updatePhysicianPhoneNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide the physician's phone number.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
            </Card.Body>
          </Card>
        </Col>

      </Row>

      <br/>
      <Button className="btn btn-primary" type="submit">Submit Request</Button>{' '}
      <Button 
        className="btn" 
        variant="outline-secondary"
        href="/tracker"
      >Cancel
      </Button>
    </Form>
    </Container>

  );
}


export default CreateReferral;