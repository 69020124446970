import React, { useContext, useState  } from 'react'
import { Alert, Button, Col, Form, Modal } from 'react-bootstrap';
import { Storage } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns'
import DataContext from "./DataContext";
// import cryptoRandomString from 'crypto-random-string';


function FileUpload(props) {
  const context_data = useContext(DataContext);
  const gw  = context_data.settings.gw_data;
  const hub = context_data.settings.hub_data;

  // console.log('GW: ', gw);
  // console.log('HUB: ', hub);
  
  const [show, setShow] = useState(false);
  const [fileData, setFileData] = useState();
  const [description, setDescription] = useState("");
  const [uploadShow, setUploadShow] = useState(false);
  const [variant, setVariant] = useState("danger") 
  const [heading, setHeading] = useState("hey") 
  const [message, setMessage] = useState("") 
  const [status, setStatus] = useState("") 

  
  const handleUpload = async (e) => {
    e.preventDefault();
    console.log("file", fileData);

    fileData.source = "Community Gateway"
    fileData.user = gw.transfer_location_id
    fileData.facility = hub.facility_id
    console.log( "before" )
    // let random_string = cryptoRandomString({length: 10});
    const UUID = uuid();
    // data.dynamo_id = ID;
    // let random_string = cryptoRandomString({length: 10});
    const key = await Storage.put(`${hub.facility_id}/${props.request.id}/${UUID}.${fileData.name.split(/[. ]+/).pop()}`, fileData, 
    {
      metadata: {description: description, document_type: "upload", user: `${gw.transfer_location_id}`, transfer_location_name: `${gw.location_name}`, upload_date: format(new Date(Date.now()), "MM/dd/yyyy h:mm a")}
    })
    .then((response) => {
      setStatus("success");
      setVariant("success");
      setHeading("File Uploaded");
      setMessage([`your file with the description: ${description}`, <br />, "has been uploaded"]);
      setDescription("");
    }).catch((err) => {
      setStatus("failure");
      setVariant("danger");
      setHeading(`Uploade Failed for description: ${description}`);
      setMessage(`${err}`);
    });
    setUploadShow(true);
    handleSetShow(false)
  };
  
  const handleSetShow = (data) => {
    setShow(data);
    props.pauseCounterFunc(data);
  }



  return (
    <>
      <Button variant={"btn btn-outline-primary btn-sm"} onClick={e => handleSetShow(true)}>Submit Documents</Button>
      <Modal animation={false} show={show} aria-labelledby="contained-modal-title-vcenter centered">
        <Modal.Header closeButton onClick={e => handleSetShow(false)} >
          <Modal.Title> Tracking Details - Request #{props.request.transfer_center_jobnumber} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Col style={{textAlign: 'center'}}>
                  <Form className="add-post" onSubmit={e => handleUpload(e)}>
                  <Form.Control required 
                      type="file" 
                      onChange={e => setFileData(e.target.files[0])} 
                  />
                  <br/>
                  <Form.Control style={{ font: '19px'}}
                      type="text" placeholder="patient charts"
                      name="description"
                      required
                      value={description}
                      // onChange={e => setDescription(e.target.value)}
                      onChange={e => setDescription(e.target.value)}
                  />
                  <br/>
                  <Form.Control type="submit"
                      className="btn btn-primary"
                      style= {{ fontsize: "19px", width: "100px"}}
                  />
                  <br/>
                  <br/>
                  <Button type="cancel"
                        className="btn btn-light"
                        value="Cancel"
                        onClick={e => handleSetShow(false)}
                        style= {{ fontsize: "19px", width: "100px"}}
                  >
                        Cancel
                  </Button>
                  </Form>
              </Col>
        </Modal.Body>
      </Modal>

      <Modal  animation={false} show={uploadShow} aria-labelledby="contained-modal-title-vcenter centered">            
      <Modal.Header closeButton onClick={e => setUploadShow(false)} >
          <Modal.Title> File Upload Status: {status} </Modal.Title>
        </Modal.Header>
          <Modal.Body>
          <Col style={{textAlign: 'center'}}>
            <Alert show={uploadShow} variant={variant}>
              <Alert.Heading>{heading}</Alert.Heading>
              <div>
              {message}
              </div>
                <Button className="btn btn-light" 
                    type="cancel"
                    onClick={() => setUploadShow(false)} 
                    variant={`outline-${variant}`}>
                  Close
                </Button>
            </Alert>
          </Col>
          </Modal.Body>
      </Modal>
    </>   
  ) 
}


export default FileUpload;