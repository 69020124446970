import { PDFDownloadLink, pdf, Image, Page, Table, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import logo from '../bwlogo.png';
import circle from '../circle.png';
import checkCircle from '../checkCircle.png';
import Title from './pdfComponents/Title';
import { compareAsc, format } from 'date-fns'
import { saveAs } from 'file-saver';
import React, { Children } from 'react';
import { Button } from 'react-bootstrap';

function PdfButton (props){
  console.log("HUb Facility Name", props)
    const circle2 = props.request.ts_capacity_approved ? checkCircle : circle
    const circle3 = props.request.transfer_center_status == "accepted" ? checkCircle : circle
    const circle4 = props.request.ts_bed_assigned ? checkCircle : circle
    const circle5 = props.request.ts_patient_delivered ? checkCircle : circle
    const bedAssignedDate =  props.request.ts_bed_assigned ? `${format(new Date(props.request.ts_bed_assigned), "MM/dd/yyyy")} @ ${format(new Date(props.request.ts_bed_assigned), "h:mm a")}` : ""
    const styles = StyleSheet.create({
        page: {
          padding: 30,
        },
        container: {
          flex: 1,
          flexDirection: 'row',
          '@media max-width: 400': {
            flexDirection: 'column',
          },
        },
        image: {
          marginBottom: 10,
        },
        circle1: {
          width: 60,
          height: 60,
          marginTop: 177
        },
        circles: {
          width: 60,
          height: 60,
          marginTop: 56
        },
        title: {
          fontSize: 25,
        },
        titleView: {
          marginTop: 75,
          marginLeft: -10,
        },
        leftBody: {
          marginTop: 100,
          marginLeft: -220,
          padding: 10
        },
        leftBodyText: {
          // padding: 10
        },
        rightBody: {
          marginTop: 100,
          position: "relative",
          marginleft: 280,
          padding: 10
        },
        leftColumn: {
          flexDirection: 'column',
          width: 170,
          paddingTop: 30,
          paddingRight: 15,
          '@media max-width: 400': {
            width: '100%',
            paddingRight: 0,
          },
          '@media orientation: landscape': {
            width: 200,
          },
        },
        border: {
          borderBottomWidth: 2,
          borderBottomColor: '#112131',
          borderBottomStyle: 'solid',
          alignItems: 'stretch',
          width: 500,
          position: "relative",
          marginBottom: 20
        },
        requestedReferral: {
          marginTop: 200,
          marginLeft: -300,
          fontSize: 14
        },
        assigned: {
          marginTop: 320,
          marginLeft: -455,
          fontSize: 14
        },
        status: {
          marginTop: 440,
          marginLeft: -455,
          fontSize: 14,
        },
        assignedBed: {
          marginTop: 560,
          marginLeft: -455,
          fontSize: 14,
        },
        patientArrived: {
          marginTop: 660,
          marginLeft: -455,
          fontSize: 14
        },
        footer: {
          fontSize: 12,
          textAlign: 'center',
          marginTop: 25,
          paddingTop: 10,
          borderWidth: 3,
          borderColor: 'gray',
          borderStyle: 'dashed',
          '@media orientation: landscape': {
            marginTop: 10,
          },
        },
        titleize: {
          fontSize: 20
        }
      });

    const MyDocument = () => (
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.container}>
              <View style={styles.leftColumn}>
                <Image src={logo} />
                <View style={styles.circle1}>
                  <Image src={checkCircle} />
                </View>
                <View style={styles.circles}>
                  <Image src={circle2} />
                </View>
                <View style={styles.circles}>
                  <Image src={circle3} />
                </View>
                <View style={styles.circles}>
                  <Image src={circle4} />
                </View>
                <View style={styles.circles}>
                  <Image src={circle5} />
                </View>
              </View>
              <View style={styles.container}>
                <View style={styles.titleView}>
                  <Title style={styles.title}>Patient Referral Tracker</Title>
                </View>
                <View style={styles.leftBody}>
                    <Text style={styles.leftBodyText}>Request #:</Text>
                    <Text style={styles.leftBodyText}>From #:</Text>
                    <Text style={styles.leftBodyText}>To:</Text>
                    <Text style={styles.leftBodyText}>Date:</Text>
                </View>
                <View style={styles.rightBody}>
                  <Text> {props.request.transfer_center_jobnumber} </Text>
                  <Text> {props.facility.location_name} </Text>
                  <Text> {props.facility.hub_facility_name}</Text>
                  <Text> {format(new Date(props.request.ts_bed_requested), "MM/dd/yyyy")} </Text>
                </View>
                <View style={styles.requestedReferral}>
                    <Text style={styles.border}> </Text>
                    <Text style={styles.titleize}> Requested Referral </Text>
                    <Text> Date/Time: {format(new Date(props.request.ts_bed_requested), "MM/dd/yyyy")} @ {format(new Date(props.request.ts_bed_requested), "h:mm a")} </Text>
                    <Text> Nurse Contact: {props.request.contact_name}  @ {props.request.contact_number} </Text>
                    <Text> Physician Contact: {props.request.physician_name}  @ {props.request.physician_number} </Text>
                </View>
                <View style={styles.assigned}>
                  <Text style={styles.border}> </Text>
                    <Text style={styles.titleize}> Assigned </Text>
                    <Text> Date/Time: {props.request.ts_capacity_approved ? `${format(new Date(props.request.ts_capacity_approved), "MM/dd/yyyy")} @ ${format(new Date(props.request.ts_capacity_approved), "h:mm a")}` : ""}</Text>
                    <Text> Nurse:  {props.request.receiving_nurse_name} </Text>
                    <Text> Nurse Contact:  {props.request.receiving_nurse_phone} </Text>
                </View>
                <View style={styles.status}>
                  <Text style={styles.border}> </Text>
                    <Text style={styles.titleize}> Status:  {props.request.transfer_center_status}</Text>
                    <Text> Date/Time: {(props.request.ts_accepted || props.request.ts_denied) ? (props.request.ts_accepted ? `${format(new Date(props.request.ts_accepted), "MM/dd/yyyy")} @ ${format(new Date(props.request.ts_accepted), "h:mm a")}` : `${format(new Date(props.request.ts_denied), "MM/dd/yyyy")} @ ${format(new Date(props.request.ts_denied), "h:mm a")}`): ""} </Text>
                    <Text> Accepting Physician: {props.request.accepting_physician_name}  </Text>
                    <Text> Physician Contact: {props.request.accepting_physician_phone} </Text>
                </View>
                <View style={styles.assignedBed}>
                  <Text style={styles.border}> </Text>
                    <Text style={styles.titleize}> Assinged Bed</Text>
                    <Text> Date/Time: {bedAssignedDate} </Text>
                    <Text> Unit:  {props.request.unit_name}  </Text>
                    <Text> Bed:  {props.request.bed_name} </Text>
                </View>
                <View style={styles.patientArrived}>
                    <Text style={styles.border}> </Text>
                    <Text style={styles.titleize}> Patient Arrived</Text>
                    <Text> Date/Time: {props.request.ts_patient_delivered ? `${format(new Date(props.request.ts_bed_requested), "MM/dd/yyyy")} @ ${format(new Date(props.request.ts_bed_requested), "h:mm a")}` : ""} </Text>
                    <Text> Admitting Physician: {props.request.phys_admit} </Text>
                    <Text> Attending Physician: {props.request.phys_attend} </Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      );

return (
  <Button variant="outline-secondary"
      size="sm"
      onClick={async () => {
        const doc = <MyDocument/>;
        const asPdf = pdf([]); // {} is important, throws without an argument
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, 'request.pdf');
      }}
  >{props.text}</Button>
);
}

export default PdfButton;