import React, { useState, useContext, useEffect } from 'react'
import DataContext from "./DataContext";
import axios from 'axios';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap';


function AlertsIndex(props) {
  const [isBusy, setIsBusy] = useState(true)
  const context_data = useContext(DataContext);
  const gw  = context_data.settings.gw_data;
  const hub = context_data.settings.hub_data;

  // console.log('GW: ', gw);
  // console.log('HUB: ', hub);

  let location = useLocation()
  let history = useHistory()
  
  const [alertRecords, setAlertRecords] = useState([]);
  
  let p = location.state
  let request = p.request
  


  async function getAlertRecords() {
    const target = "https://t8p53amzd6.execute-api.us-east-1.amazonaws.com/dev/communityGatewayAlertsIndex"
    axios(target, {
      method: 'POST',
      data: JSON.stringify(
        {
          location_id: location.state.request.id, 
          from_transfer_location_id: gw.transfer_location_id, 
          facility_id: gw.facility_id, 
          tenant: gw.tenant
        }
      )
    })
    .then((response) => {
      setAlertRecords(response.data);
    })
    .catch((err) => console.log("Err: ", err))
    .finally(() => {
      console.log("AlertRecords: ", alertRecords);
      console.log("AlertRecords.length: ", alertRecords.length);
      setIsBusy(false);
    })
  }
  

  useEffect(() => {
    if (alertRecords.length <= 0 && !isBusy) {
      history.push("/setupAlerts/new", {request: request, shortcode: p.shortcode, facility: p.facility, facilityInfo: p.facilityInfo})
    }
  },[getAlertRecords])
  
  useEffect(() => {
      getAlertRecords()
  },[])

  const container = isBusy ? (<div></div>) : ( <Container fluid>
    <Row>
      <Col md={1}></Col>
      <Col md={10}>
        <Card className="card mb-3" style={{marginTop:"20px"}}>
          <Card.Header as="h5">Request Alerts</Card.Header>
          <Card.Body className="text-start">
              <div>Request #: <b>{p.request.transfer_center_jobnumber }</b></div><br/>
              <Table responsive bordered hover>
                <thead>
                  <tr>
                    <th> Alert Type</th>
                    <th> Notification Status</th>
                    <th> Device Type</th>
                    <th> Destination</th>
                  </tr>
                </thead>
                <tbody>
                  {alertRecords.map(( record, index ) => {
                    {console.log("Record: ", record.status);}
                    return (
                      <tr className="manage_resource_row" key={index}>
                      <td> {record.event_type_label }</td>
                      <td> {record.status }</td>
                      <td> {record.device_type.substring(6) }</td>
                      {/* (6,10) */}
                      <td> {record.device_target }</td>
                    </tr>
                    )
                  })}
                </tbody>
              </Table>
          </Card.Body>
        </Card>

        <div>
          <Link style={{fontSize: "14px", borderRadius: "5px", border: "1px solid #ddd5d5"}} className="text-center btn btn-primary" to ={{ pathname: "/setupAlerts/new", state: {request: request, shortcode: p.shortcode, facility: p.facility, facilityInfo: p.facilityInfo}}} replace>
              Setup Alerts
          </Link>{' '}
          <Button 
            className="btn" 
            variant="outline-secondary"
            href="/tracker"
          >Cancel
          </Button>
        </div>


      </Col>
    </Row>
  </Container>
  ) 
  
  return (
    <div>
      {container}
    </div>
  );
    
}

export default AlertsIndex;