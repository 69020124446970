import React, { useState, useContext, useEffect  } from 'react'
import DataContext from "./DataContext";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useHistory, useLocation } from "react-router-dom";
import { Card, Form, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getWeeksInMonth } from 'date-fns';
import { getWeek } from 'date-fns/esm';


function SetupAlerts(props) {
  const context_data = useContext(DataContext);
  const gw  = context_data.settings.gw_data;
  const hub = context_data.settings.hub_data;

  console.log('GW: ', gw);
  console.log('HUB: ', hub);

  let location = useLocation()
  let history = useHistory()

  const [facility, setFacility] = useState(gw)

  const [showEmail, setShowEmail] = useState(false)

  const [devicetype, setDevicetype]   = React.useState("DeviceSMS");
  const [pataccepted, setPataccepted] = React.useState(true);
  const [patdeclined, setPatdeclined] = React.useState(true);
  const [bedassigned, setBedassigned] = React.useState(true);
  const [bedarrived,  setBedarrived ] = React.useState(true);
  
  const [ form, setForm ] = useState({});
  const [ validated, setValidated ] = useState(false);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
  };    

  function updateDeviceType(e) {
    setDevicetype(e.target.value)
      
    if (e.target.value === "DeviceEmail") {
      setShowEmail(true)
    } else {
      setShowEmail(false)
    }
    console.log("Alert: ", alert)
  }

  function updatePhoneNumber(number) {
    setForm({...form, device_target: number})
  }


  const yesNoSelectOptionsList = ["Yes", "No"].map((selectOption, index) => {
    return <option key={index} value={selectOption}>{selectOption}</option>
  });
  const deviceOptions = [["DeviceSMS", "Mobile Phone (SMS)"], ["DeviceEmail","Email"]]
  const deviceSelectOptionsList = deviceOptions.map((selectOption, index) => {
    return <option key={index} value={selectOption[0]}>{selectOption[1]}</option>
  });

          
  async function handleCreateAlert(data) {
    const target = "https://t8p53amzd6.execute-api.us-east-1.amazonaws.com/dev/communityGatewaySetupAlert"
    console.log("referral: ", data)

    let result = await axios(target, {
      method: 'POST',
      data: JSON.stringify({referral: data, facility_id: data.facility_id, tenant: data.tenant})
    }).then((response) => {
      console.log("result.data", response)
      let alertNotice = {}
      alertNotice = {status: "success", variant: "success", heading: "Request Created", message: `you have created a new transfer request` }
      history.push("/tracker/", { params: {facility: facility, alertNotice: alertNotice}});
    }).catch((err) => {
      let alertNotice = {}
      alertNotice = {status: "failure", variant: "danger", heading: "Request Failed", message: `the transfer request was not successful` }
      history.push("/tracker/", { params: {facility: facility, alertNotice: alertNotice}});
    });
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const referral = event.currentTarget;

    console.log(event);

    if (referral.checkValidity() === false) {
      console.log("NOT VALID")
      event.stopPropagation();
    } else {
      console.log("FORM DATA:");
      console.log(form);

      const data = {
        from_transfer_location_id: gw.transfer_location_id, 
        facility_id: gw.facility_id, 
        tenant: gw.tenant,
        ac_request_id: location.state.request.id, 
        device_type: devicetype,  
        txc_accepted: pataccepted, 
        txc_declined: patdeclined, 
        txc_bed_assigned: bedassigned, 
        ac_placed: bedarrived, 
        device_target: form.device_target, 
        device_target_email: form.device_target_email
      }

      console.log("FINAL DATA:");
      console.log(data);

      handleCreateAlert(data);
    }
    setValidated(true);
  };

  

  return (
    <Container fluid>
    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Card className="card text-dark bg-light mb-3" style={{marginTop:"20px"}}>
            <Card.Header as="h5">Setup Alerts</Card.Header>
            <Card.Body className="text-start">

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalDeviceType">
                  <Form.Label column md={{ span: 3, offset: 1 }}>
                    Device Type
                  </Form.Label>
                  <Col md={5}>
                  <Form.Select
                    name="deviceType"
                    required
                    value={form.device_type}
                    onChange={e => updateDeviceType(e)}
                  >
                    {deviceSelectOptionsList}
                  </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPhone"
                    style={showEmail ? {display: "none"} : {display: ""}}>
                  <Form.Label column md={{ span: 3, offset: 1 }}>
                    Phone Number
                  </Form.Label>
                  <Col md={5}>
                    <PhoneInput
                      inputProps={{
                        name: 'device_target',
                        required: true
                      }}
                      placeholder={""}
                      country={'us'}
                      onlyCountries={['us']}
                      disableDropdown={true}
                      disableCountryCode={true}
                      value={form.device_target}
                      onChange={updatePhoneNumber}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalContactEmail"
                            style={showEmail ? {display: ""} : {display: "none"}}>
                  <Form.Label column column md={{ span: 3, offset: 1 }}>
                    Email
                  </Form.Label>
                  <Col md={5}>
                    <Form.Control 
                      type="email" 
                      placeholder="name@example.com"
                      name="contact_email"
                      className="required"
                      value={form.device_target_email}
                      onChange={ e => setField('device_target_email', e.target.value) }
                    />
                  </Col>
                </Form.Group>

                <hr style={{marginBottom:"30px", marginTop:"20px"}} />
                

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
                  <Col md={{ span: 3, offset: 1 }}>
                  <Form.Check 
                    type="switch"
                    id="patient_accepted_cb"
                    label="Patient Accepted"
                    defaultChecked={pataccepted}
                    onChange={() => setPataccepted(!pataccepted)}
                  />
                  </Col>
                  <Col md={{ span: 7 }}>
                    <span className="text-muted">Receive notification when a patient referral is accepted</span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
                  <Col md={{ span: 3, offset: 1 }}>
                    <Form.Check 
                      type="switch"
                      id="patient_declined_cb"
                      label="Patient Declined"
                      defaultChecked={patdeclined}
                      onChange={() => setPatdeclined(!patdeclined)}
                    />
                  </Col>
                  <Col md={{ span: 7 }}>
                    <span className="text-muted">Receive notification if a patient referral is declined</span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
                  <Col md={{ span: 3, offset: 1 }}>
                    <Form.Check 
                      type="switch"
                      id="bed_assigned_cb"
                      label="Bed Assigned"
                      defaultChecked={bedassigned}
                      onChange={() => setBedassigned(!bedassigned)}
                    />
                  </Col>
                  <Col md={{ span: 7 }}>
                    <span className="text-muted">Receive notification when the patient's bed is assigned</span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
                  <Col md={{ span: 3, offset: 1 }}>
                    <Form.Check 
                      type="switch"
                      id="bed_arrived_cb"
                      label="Arrived to Bed"
                      defaultChecked={bedarrived}
                      onChange={() => setBedarrived(!bedarrived)}
                    />
                  </Col>
                  <Col md={{ span: 7 }}>
                    <span className="text-muted">Receive notification when the patient has arrived to their bed</span>
                  </Col>
                </Form.Group>

            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br/>
      <Button className="btn btn-primary" type="submit">Create Alerts</Button>{' '}
      <Button 
        className="btn" 
        variant="outline-secondary"
        href="/tracker"
      >Cancel
      </Button>
    </Form>
    </Container>

  );
}

export default SetupAlerts;