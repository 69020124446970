import React, { useState, useEffect, useContext } from 'react'
import DataContext from "./DataContext";
import axios from 'axios';
import AlertDismissable from './Alert';  
import Counter from './Counter';
import FileUpload from './FileUpload';
import ActiveRequests from './ActiveRequests'; 
import { useLocation } from "react-router-dom";
import { Button, Container, Row, Col, Tabs, Tab } from 'react-bootstrap';


    
function Tracker(props) {

  const { settings, setSettings } = useContext(DataContext);
  const [isReady, setIsReady] = useState(0);
  console.log("settings :",settings);
  const gw  = settings.gw_data;
  const hub = settings.hub_data;

  console.log('GW: ', gw);
  console.log('HUB: ', hub);
  
  let location = useLocation()
  
  let alertNotice = (location.state && location.state.params.alertNotice) ? location.state.params.alertNotice
  : "";
  
  const [referrals, setReferrals] = useState([])
  const [facilityInfo, setFacilityInfo] = useState([])

  const reconcileRequests = function() {
    let referral = settings.referral;
    let x = referrals.map(e => {return e.dynamo_id});
    let notIncluded;
    if (referral && Object.keys(referral).length !== 0) {
        notIncluded = x.includes(referral.dynamo_id) === false;
    } else {
        notIncluded = false
    }
    if (referral && notIncluded) {
        if ((new Date().getTime() - new Date(referral.ts_bed_requested))/1000 > 60) {
            referral.status_tracker_class = "BTN-DANGER";
        }
        setReferrals([...referrals, referral])
    } else {
        window.localStorage.setItem('referral', JSON.stringify({}));
    }
  }

  let foShow = false
  let variant = ""
  let heading = ""
  let message = ""
  if (alertNotice.status) { 
      foShow = true
      variant = alertNotice.variant
      heading = alertNotice.heading
      message = alertNotice.message
  } else {
      foShow = false
  }
  
  const [show, setShow] = useState(false);
  const [pauseCounter, setPauseCounter] = useState(false)
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  useEffect(() => {
    fetchTrackerData()
  }, []);

  useEffect(() => {
    reconcileRequests()
  }, [isReady]);

  
  const updatePauseCounter = (data) => {
    setPauseCounter(data)
    console.log("updatePauseCounter:", data);
  }

  console.log("pauseCounter: ", pauseCounter)
  async function fetchTrackerData() {
    const targetUrl = 'https://t8p53amzd6.execute-api.us-east-1.amazonaws.com/dev/cgv2Tracker'       
    await axios(targetUrl, {method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
          // 'Origin': 'https://840008491bbb.ngrok.io'
      },
      data: JSON.stringify({
        from_transfer_location_id: gw.transfer_location_id, 
        facility_id: hub.facility_id, 
        tenant: hub.tenant})
      }).then((response) => {
          setReferrals(response.data.requests);
          setFacilityInfo(response.data.facility_info);
          setIsReady(isReady+1)
      }).catch(err => console.log("Err: ", err));
  }


  return (
        <Container fluid>
          <Row style={{marginTop: "10px"}}>
            <Col sm={4} md={4}>
              <h5>Community Gateway Tracker</h5>
              <h6>{gw.location_name}</h6>
            </Col>      
            <Col sm={4} md={4}>
              <h3>{hub.facility_name}</h3>
              <div>Phone: {hub.phone_number}</div>
              <div>Fax:  {hub.fax_number}</div>
              <div style={{marginTop: "10px"}}>
                <Button href="/referral/new">Submit New Request</Button>
              </div>
            </Col>
            <Col sm={4} md={4}>
              <Counter pause={pauseCounter}/>
            </Col>
          </Row>
              
          
          {/* <AlertDismissable foShow={foShow} variant={variant} heading={heading} message={message}/>  */}

          <Row>
            <Col md={9} ></Col>
            <Col md={1} className="text-start d-none d-lg-block">
              Requested
            </Col>
            <Col md={1} className="text-center d-none d-lg-block">
              Processing
            </Col>
            <Col md={1} className="text-center d-none d-lg-block">
              Bed Assigned
            </Col>
          </Row>
            
          <Row>
            <Col md={12} >
              <Tabs defaultActiveKey="activeRequests">
                <Tab style={{}} eventKey="activeRequests" title="Active Requests" className="">
                  <div role="tabpanel" className="tab-pane active" id="external-active" >
                    <Row>
                      <Col md={12}>
                      <ActiveRequests pauseCounterFunc={updatePauseCounter} displayAlertLink={true} referrals={referrals.filter(x => x.complete != true)} shortcode={gw.short_code} facility={gw} facilityInfo={facilityInfo} />
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey="completedRequests" title="Completed Requests">
                  <div role="tabpanel" className="tab-pane active" id="external-active" >
                  <Row>
                    <Col md={12}>
                    <ActiveRequests pauseCounterFunc={updatePauseCounter} displayAlertLink={true} referrals={referrals.filter(x => x.complete == true)} shortcode={gw.short_code} facility={gw} facilityInfo={facilityInfo} />
                    </Col>
                  </Row>
                  </div>
                </Tab>
                <Tab eventKey="documentUpload" title="Upload Unlinked Document">
                  <div role="tabpanel" className="tab-pane active" id="external-active" >
                  <Row>
                    <Col md={12}>
                      <br/>
                      <FileUpload pauseCounterFunc={updatePauseCounter} updateAlertStatus={{}} index={"a"} request={{transfer_center_jobnumber: 'Unlinked', id: 'cgv2_unlinked_document'}} facility={gw} />
                    </Col>
                  </Row>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>

        </Container>
  ) 

}


export default Tracker;