import React from 'react'
import { format } from 'date-fns'
import VerticalTracker from './VerticalTracker'; 
import FileUpload from './FileUpload'; 
import { Link, useHistory } from "react-router-dom";
import { Alert, Button, Col, Row, Table } from 'react-bootstrap';


    
function ActiveRequests(props) {
   const referrals = props.referrals
   const updateAlertStatus = props.updateAlertStatus
   let history = useHistory();
    return (
        <div>
        <br/>
            {referrals.map(( request, index ) => {
                let variant = ""
                let status  = ""
                
                console.log("tracker class: "+ request.status_tracker_class.split('-')[1])
                console.log("date format: "+ format(new Date(request.ts_bed_requested), "MM/dd/yyyy"))
                let styleClass = request.status_tracker_class.split('-')[1].toLowerCase();
                if (request.status_tracker_class === 'btn-success') {
                        status = "Accepted"
                        variant = "success"
                } else if (request.status_tracker_class === 'btn-danger') {
                        status = "Declined"
                        variant = "danger"
                } else if (request.status_tracker_class === 'BTN-DANGER') {
                        status = "Not Received"
                        variant = "danger"
                } else if (request.status_tracker_class === 'btn-primary') {
                        status = "Consult"
                        variant = "primary"
                } else if (request.status_tracker_class === 'btn-warning') {
                    status = "Cancelled"
                    variant = "warning"
                } else if (request.status_tracker_class === 'btn-secondary') {
                    status = "Request In Transit"
                    variant = "dark"
                } else {
                    status = "Processing"
                    variant = "secondary"
                };

                let displayAlertLink = props.displayAlertLink ? "true" : "none"
                
                async function handleLink() {
                    props.pauseCounterFunc(true);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    history.push("/alerts", {request: request, shortcode: props.shortcode, facility: props.facility, facilityInfo: props.facilityInfo});
                }
                
                return (
                    <div  key={index}>
                    <Row className="request-table">
                        <Col md={1} style={{textAlign: 'left'}}>
                        <Alert variant={variant} style={{width: "140px", height: "100px"}}>
                            <div className="text-center" style={{marginTop: "20px"}}>
                                <span style={{fontWeight: "bold", fontSize: "1.2em"}}>{status}</span>
                            </div>
                        </Alert>
                        </Col>
                        <Col md={2}>
                            <VerticalTracker pauseCounterFunc={props.pauseCounterFunc} index={index} request={request} facility={props.facility} />
                            <div style={{marginTop: "10px"}}>
                                <Button onClick={handleLink} variant={"btn btn-default"}  className="btn btn-outline-primary btn-sm">
                                       Setup Alerts
                                </Button>
                                {'  '}
                            </div>
                            <div style={{marginTop: "10px"}}>
                                <FileUpload pauseCounterFunc={props.pauseCounterFunc} updateAlertStatus={updateAlertStatus} index={index} request={request} facility={props.facility} />
                            </div>
                        </Col>

                        <Col md={2} style={{textAlign: 'left', fontSize:".8em"}}>
                            <Table responsive bordered hover size="sm">
                                <tbody>
                                    <tr>
                                        <td style={{width:"85px", fontWeight:"bold"}} className="table-light">Request #:</td>
                                        <td>{request.transfer_center_jobnumber}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"85px", fontWeight:"bold"}} className="table-light">Service:</td>
                                        <td>{request.ac_service_line}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"85px", fontWeight:"bold"}} className="table-light">Date:</td>
                                        <td>{format(new Date(request.ts_bed_requested), "MM/dd/yyyy")}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"85px", fontWeight:"bold"}} className="table-light">Time:</td>
                                        <td>{format(new Date(request.ts_bed_requested), "h:mm a")}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>

                        <Col md={2} style={{textAlign: 'left', fontSize:".8em"}}>
                            <Table responsive bordered hover size="sm">
                                <tbody>
                                    <tr>
                                        <td style={{width:"85px", fontWeight:"bold"}} className="table-light">Admin:</td>
                                        <td>{request.capacity_admin}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"85px", fontWeight:"bold"}} className="table-light">Physician:</td>
                                        <td>{request.accepting_physician_name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"85px", fontWeight:"bold"}} className="table-light">Nurse:</td>
                                        <td>{request.receiving_nurse_name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"85px", fontWeight:"bold"}} className="table-light">Phone:</td>
                                        <td>{request.rcvg_nurse_phone}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>

                        <Col md={2} style={{textAlign: 'left', fontSize:".8em"}}>
                            <Table responsive bordered hover size="sm">
                                <tbody>
                                    <tr>
                                        <td style={{width:"85px", fontWeight:"bold"}} className="table-light">Unit:</td>
                                        <td>{request.unit_name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"85px", fontWeight:"bold"}} className="table-light">Bed:</td>
                                        <td>{request.bed_name}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>


                        <Col md={3}>
                            <table style={{width:"100%"}}>
                                <tbody>
                                    <tr>
                                        <td style={{paddingLeft: "60px"}}>
                                            <div id="tracker-header" className="">
                                                <ul className="bigger highlight-active">
                                                    <li className="active" style={{color:"white"}}></li>
                                                    <li className={request.processing_tracker_class}></li>
                                                    <li className={request.bed_assigned_tracker_class}></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <hr style={{marginTop:"-20px"}}/>
                    </div>
                )
            })}  
        </div>
    ) 
}




export default ActiveRequests;


