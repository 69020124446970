import React from 'react'
import { Route } from 'react-router-dom'

// Custom components
import AlertDismissable from './components/Alert'; 
import Index from './components/Index'; 
import Tracker from './components/Tracker'; 
import AlertsIndex from './components/AlertsIndex'; 
import SetupAlerts from './components/SetupAlerts'; 
import CreateReferral from './components/CreateReferral'; 
import ReferralSuccess from './components/ReferralSuccess'; 


const BaseRouter = () => {    
  return (        
    <div> 
      <Route path="/cg/:pin" render={(props) => (<Index {...props} />)} />            
      <Route exact path='/alert' component={props => <AlertDismissable {...props} />}  />      
      <Route exact path='/' component={props => <Index {...props} />}  />      
      <Route exact path='/index' component={props => <Index {...props} />} />      
      <Route exact path='/tracker' component={props => <Tracker {...props} />} /> 
      <Route exact path='/referral/new' component={props => <CreateReferral {...props} />} /> 
      <Route exact path='/referral/success' component={props => <ReferralSuccess {...props} />} /> 
      <Route exact path='/setupAlerts/new' component={props => <SetupAlerts {...props} />} /> 
      <Route exact path='/alerts' component={props => <AlertsIndex {...props} />} /> 
    </div>    
  )
}; 
export default BaseRouter;