import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function AlertDismissible(props) {
    const {foShow, variant, heading, message} = props
    const [show, setShow] = useState(foShow);
    return (
      <>
        <Alert show={show} variant={variant}>
          <Alert.Heading>{heading}</Alert.Heading>
          <div>
           {message}
          </div>
          <span className="d-flex justify-content-end">
            <Button onClick={() => setShow(false)} variant={`outline-${variant}`}>
              Close
            </Button>
          </span>
        </Alert>
      </>
    );
  }
  

  export default AlertDismissible;