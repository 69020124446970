import MainNavbar from './components/MainNavbar'; 
import DataContext from './components/DataContext'; 
import { BrowserRouter as Router  } from 'react-router-dom';
import BaseRouter from './routes'; // All routes located
import { useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import 'bootstrap/dist/css/bootstrap.min.css';
Amplify.configure(awsconfig);


function App() {
  const hub_storage = JSON.parse(window.localStorage.getItem('hub_data'));
  const gw_storage  = JSON.parse(window.localStorage.getItem('gw_data'));
  const referral_storage  = JSON.parse(window.localStorage.getItem('referral'));
  const data_context = {
    hub_data: hub_storage,
    gw_data: gw_storage,
    referral: referral_storage
  }
  const [settings, setSettings] = useState(data_context);
  const context = { settings, setSettings };
  
  return (
    <DataContext.Provider value={context}>
      <div className="App">         
          <Router>       
            <MainNavbar /> 
            <BaseRouter />      
          </Router>    
      </div>  
    </DataContext.Provider>
  );
}

export default App;
